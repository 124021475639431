import React from 'react';
import { Modal } from 'react-bootstrap';
import Dropdown from '../lib2/index';
import { BassTunings, GuitarTunings, UkeleleTunings, useSession, Tunings, Notes, NoteNames } from '../hooks';
import './Tuning.css';

export function Tuning(props: any) {
    const [state, dispatch] = useSession();
    const {instrument, stringTuning} = state;
    const {setStringTuning} = dispatch;
    const [showModal, setShowModal] = React.useState(false);

    let tunings: Tunings[] = GuitarTunings;

    if(instrument === "Guitar") {
        tunings = GuitarTunings;
    } else if(instrument === "Bass") {
        tunings = BassTunings;
    } else if(instrument === "Ukelele") {
        tunings = UkeleleTunings;
    }

    const closeModal = () => {
        setShowModal(false);
    };

    const openModal = () => {
        setShowModal(true);
    };

    const selectTuning = (tuning: Tunings) => {
        if(instrument === "Guitar") {
            localStorage.setItem("GuitarTuning", JSON.stringify(tuning.notes));
        } else if(instrument === "Bass") {
            localStorage.setItem("BassTuning", JSON.stringify(tuning.notes));
        } else if(instrument === "Ukelele") {
            localStorage.setItem("UkeleleTuning", JSON.stringify(tuning.notes));
        }

        setStringTuning(tuning.notes);
    };

    const isActive = (tuning: Tunings) => {
        if(tuning.notes.length !== stringTuning.length) {
            return false;
        }
        
        for(let i = 0; i < tuning.notes.length; i++) {
            if(tuning.notes[i] !== stringTuning[i]) {
                return false;
            }
        }

        return true;
    };

    const setNoteTuning = (note: string, index: number) => {
        let tuningCopy: Notes[] = [...stringTuning];
        tuningCopy[index] = Notes[Notes[NoteNames.indexOf(note)]];
        const tuning: Tunings = {name: "Custom", notes: tuningCopy};
        selectTuning(tuning);
    };

    const scaleTuning = (steps: number) => {
        let tuningCopy: Notes[] = [...stringTuning];

        for(let i = 0; i < tuningCopy.length; i++) {
            let index = (tuningCopy[i] + steps) % 12;

            if(index < 0) {
                index += 12;
            }

            tuningCopy[i] = Notes[Notes[index]];
        }

        const tuning: Tunings = {name: "Custom", notes: tuningCopy};
        selectTuning(tuning);
    };

    return (
        <div>
            <button className="btn btn-outline-primary btn-lg" onClick={openModal}>Select Tuning</button>
            <Modal show={showModal} onHide={closeModal} size="lg" dialogClassName="text-center justify-content-center">
                <Modal.Body>
                    <div className="container-fluid flex-column">
                        <div className="row no-gutters header">
                            <div className="col d-inline-block">
                                <h2>Select Tuning</h2>
                            </div>
                        </div>
                        <div className="row tuningRow no-gutters presets text-center justify-content-center">
                            {tunings.map((tuning: Tunings, index: number) => {
                                    return <div key={index} className="tuningButtonDiv">
                                        <button key={" " + index} className={"btn btn-outline-primary tuningButton " + (isActive(tuning) ? "tuningButtonActive" : "")} 
                                                onClick={() => selectTuning(tuning)}>
                                            {tuning.name}
                                        </button>
                                    </div>
                                })
                            }
                        </div>
                        <div className="row no-gutters tuningRow text-center justify-content-center">
                            <button className="halfstep down btn btn-outline-danger btn-lg" onClick={() => scaleTuning(-1)}>-</button>
                            {stringTuning.map((note: Notes, i: number) => {
                                    return <div key={i}>
                                        <Dropdown title={NoteNames[note]} key={" " + i}>
                                            {NoteNames.map((noteName: string, j: number) => {
                                                    return <Dropdown.Item key={j} onClick={() => setNoteTuning(noteName, i)}>
                                                            {noteName}
                                                        </Dropdown.Item>
                                                })
                                            }
                                        </Dropdown>
                                    </div>
                                })
                            }
                            <button className="halfstep up btn btn-outline-success btn-lg" onClick={() => scaleTuning(1)}>+</button>
                        </div>
                        <div className="row no-gutters">
                            <div className="col flex">
                                <button className="btn btn-outline-danger float-right" onClick={closeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
