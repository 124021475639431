import React from 'react';
import { Keys } from "../components";
import { PianoTunings } from '../hooks';

export function Keyboard(props: any) {
    return (
        <div>
            <Keys keys={PianoTunings.Standard}/>
        </div>
    );
}
