import React, { useReducer, Dispatch, useContext, createContext } from "react";
import { BassTunings, GuitarTunings, Notes, UkeleleTunings } from "./constants";

export interface Action<TType extends string, TPayload = void> {
	type: TType;
	payload: TPayload;
	error?: boolean;
}

interface SelectedMode {
    modeType: string;
    modeName: string;
}

interface ISessionState {
    instrument: string;
    mode: SelectedMode;
    scale: string;
    startNote: string;
    stringTuning: Notes[];
}

const getInstrument = () => {
    let instrument: string | null = localStorage.getItem("Instrument");

    if(instrument === null) {
        return "Guitar";
    } else {
        return instrument;
    }
}

const getMode = () => {
    let modeType: string | null = localStorage.getItem("ModeType");
    let modeName: string | null = localStorage.getItem("ModeName");
    
    if(modeType === null || modeName === null) {
        return {modeType: "Major", modeName: "Ionian / Major"};
    } else {
        return {modeType: modeType, modeName: modeName};
    }
}

const getScale = () => {
    let scale: string | null = localStorage.getItem("Scale");

    if(scale === null) {
        return "Full Scale";
    } else {
        return scale;
    }
}

const getStartNote = () => {
    let startNote: string | null = localStorage.getItem("StartNote");

    if(startNote === null) {
        return "C";
    } else {
        return startNote;
    }
}

export const getStringTuning = (instrument: string) => {
    if(instrument === "Guitar") {
        const tuningStr = localStorage.getItem("GuitarTuning");

        if(tuningStr !== null) {
            const tuning: Notes[] = JSON.parse(tuningStr);
            return tuning;
        } else {
            return GuitarTunings[0].notes;
        }
    } else if(instrument === "Bass") {
        const tuningStr = localStorage.getItem("BassTuning");

        if(tuningStr !== null) {
            const tuning: Notes[] = JSON.parse(tuningStr);
            return tuning;
        } else {
            return BassTunings[0].notes;
        }
    } else if(instrument === "Ukelele") {
        const tuningStr = localStorage.getItem("UkeleleTuning");

        if(tuningStr !== null) {
            const tuning: Notes[] = JSON.parse(tuningStr);
            return tuning;
        } else {
            return UkeleleTunings[0].notes;
        }
    }

    return GuitarTunings[0].notes;
}

const initialSessionState: ISessionState = {
    instrument: getInstrument(),
    mode: getMode(),
    scale: getScale(),
    startNote: getStartNote(),
    stringTuning: getStringTuning(getInstrument()),
};

type HandledActions = Readonly<
      Action<'SELECT_INSTRUMENT', { instrument: string }>
    | Action<'SELECT_MODE', { mode: SelectedMode }>
    | Action<'SELECT_SCALE', { scale: string }>
    | Action<'SELECT_START_NOTE', { startNote: string }>
    | Action<'SELECT_STRING_TUNING', { stringTuning: Notes[] }>
>;

const buildDispatch = (dispatch: Dispatch<HandledActions>) => ({
    setInstrument: (instrument: string) => {
        dispatch({ type: 'SELECT_INSTRUMENT', payload: { instrument }});
    },
    setMode: (mode: SelectedMode) => {
        dispatch({ type: 'SELECT_MODE', payload: { mode }});
    },
    setScale: (scale: string) => {
        dispatch({ type: 'SELECT_SCALE', payload: { scale }});
    },
    setStartNote: (startNote: string) => {
        dispatch({ type: 'SELECT_START_NOTE', payload: { startNote }});
    },
    setStringTuning: (stringTuning: Notes[]) => {
        dispatch({ type: 'SELECT_STRING_TUNING', payload: { stringTuning }});
    },
});

const assertActionsAreHandled = (action: never) => { /* compile-time assertion */};

const sessionReducer = (state: ISessionState, action: HandledActions): ISessionState => {
    switch(action.type) {
        case 'SELECT_INSTRUMENT': {
            return { ...state, instrument: action.payload.instrument };
        }
        case 'SELECT_MODE': {
            return { ...state, mode: action.payload.mode };
        }
        case 'SELECT_SCALE': {
            return { ...state, scale: action.payload.scale };
        }
        case 'SELECT_START_NOTE': {
            return { ...state, startNote: action.payload.startNote };
        }
        case 'SELECT_STRING_TUNING': {
            return { ...state, stringTuning: action.payload.stringTuning };
        }
        default:
            assertActionsAreHandled(action);
            return state;
    }
};

type SessionDispatch = ReturnType<typeof buildDispatch>;

interface ISessionProviderProps {
    children: JSX.Element;
}

interface ISessionContextProps {
    state: ISessionState;
    actionableDispatch: SessionDispatch | null;
}

const SessionContext = createContext<ISessionContextProps>({ state: initialSessionState, actionableDispatch: null });

export const SessionProvider = ({ children }: ISessionProviderProps) => {
    const [state, dispatch] = useReducer(sessionReducer, initialSessionState);
    const actionableDispatch = buildDispatch(dispatch);
    return (
        <SessionContext.Provider value={{state, actionableDispatch}}>
            {children}
        </SessionContext.Provider>
    )
};

export const useSession = () => {
    const { state, actionableDispatch } = useContext(SessionContext);
    return [state, actionableDispatch] as [ISessionState, SessionDispatch];
};
