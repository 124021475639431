import React from 'react';
import { useSession, Notes, NoteNames, getChordsInScale, getChordType, getMode } from '../hooks';
import { Tuning } from '../components';
import "./Sidebar.css";

export function Sidebar() {
    const [state] = useSession();
    const {instrument, mode, scale, startNote} = state;

    const selectedMode = getMode(mode.modeType);
    const offset = selectedMode.names.indexOf(mode.modeName);
    const startNoteIndex = NoteNames.indexOf(startNote);
    const notesInKey = getChordsInScale(selectedMode, offset, startNoteIndex, "Full Scale");
    const notesInScale = getChordsInScale(selectedMode, offset, startNoteIndex, scale);

    const CHORD_NUMS: string[] = ["I", "II", "III", "IV", "V", "VI", "VII"];

    const isNoteInScale = (note: Notes) => {
        return notesInScale.indexOf(note) !== -1;
    };

    return (
        <div className="sidenav col">
            <h1 className="title">Chords</h1>
            {notesInKey.map((note: Notes, index: number) => {
                    return <div key={index} className={"row no-gutters " + (isNoteInScale(note) ? "" : "text-muted")}>
                        <div key={" " + index} className="chord col1">
                            <h2 key={"  " + index}>{CHORD_NUMS[index]}</h2>
                        </div>
                        <div key={"    " + index} className="chord col2">
                            <h2 key={"      " + index}>{" - "}</h2>
                        </div>
                        <div key={"  " + index} className="chord col3">
                            <h2 key={"    " + index}>{NoteNames[note] + getChordType(selectedMode, offset, index)}</h2>
                        </div>
                    </div>
                })
            }
            <br />
            <br />
            <br />
            {
                instrument !== "Keyboard" && <Tuning />
            }
        </div>
    );
}