import React from 'react';
import { Header, Keyboard, Strings } from '../components';
import { useSession } from '../hooks';

export function Landing() {
    const [state] = useSession();
    const {instrument} = state;

    return (
        <div className="landing">
            <Header></Header>
            <div>
                {
                    instrument !== "Keyboard" && <Strings />
                }
                {
                    instrument === "Keyboard" && <Keyboard />
                }
            </div>
        </div>
    );
}
