import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { SessionProvider } from './hooks';
import { Landing } from "./pages";
import './index.css';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.css';

function Index() {
    return (
        <div>
            <Helmet>
                <title>Scale Visualizer</title>
            </Helmet>
            <SessionProvider>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" component={Landing} />
                        <Route exact path="*" render={() => <h1>Error 404</h1>} />
                    </Switch>
                </BrowserRouter>
            </SessionProvider>
        </div>
    )
}

ReactDOM.render(
    <React.StrictMode>
        <Index />
    </React.StrictMode>,
    document.getElementById('root')
);
