export enum Notes {
    C = 0,
    CSHARP = 1,
    D = 2,
    DSHARP = 3,
    E = 4,
    F = 5,
    FSHARP = 6,
    G = 7,
    GSHARP = 8,
    A = 9,
    ASHARP = 10,
    B = 11,
};

export const NoteNames: string[] = ["C", "C♯", "D", "D♯", "E", "F", "F♯", "G", "G♯", "A", "A♯", "B"];

export const ScaleTypes = ["Full Scale", "Pentatonic", "Triads"];

export const Instruments = ["Guitar", "Bass", "Ukelele", "Keyboard"];

export const PianoTunings = {
    Standard: {
        BlackKeys: [Notes.CSHARP, Notes.DSHARP, null, Notes.FSHARP, Notes.GSHARP, Notes.ASHARP, null],
        WhiteKeys: [Notes.C, Notes.D, Notes.E, Notes.F, Notes.G, Notes.A, Notes.B],
    },
};

export interface Tunings {
    name: string;
    notes: Notes[];
}

export const GuitarTunings: Tunings[] = [
    {name: "Standard", notes: [Notes.E, Notes.B, Notes.G, Notes.D, Notes.A, Notes.E]},
    {name: "DropD", notes: [Notes.E, Notes.B, Notes.G, Notes.D, Notes.A, Notes.D]},
    {name: "DADGAD", notes: [Notes.D, Notes.A, Notes.G, Notes.D, Notes.A, Notes.D]},
    {name: "OpenE", notes: [Notes.E, Notes.B, Notes.GSHARP, Notes.E, Notes.B, Notes.E]},
];

export const BassTunings: Tunings[] = 
[
    {name: "Standard", notes: [Notes.G, Notes.D, Notes.A, Notes.E]},
    {name: "Drop D", notes: [Notes.G, Notes.D, Notes.A, Notes.D]},
    {name: "Drop C", notes: [Notes.F, Notes.C, Notes.G, Notes.C]},
];

export const UkeleleTunings: Tunings[] = [
    {name: "Standard", notes: [Notes.A, Notes.E, Notes.C, Notes.G]},
    {name: "Bass", notes: [Notes.G, Notes.D, Notes.A, Notes.E]},
    {name: "Baritone", notes: [Notes.E, Notes.B, Notes.G, Notes.D]},
];

export interface Mode {
    name: string,
    equation: number[],
    names: string[],
    chordTypes: string[]
}

export const modes: Mode[] = [
        {
            name: "Major",
            equation: [2, 2, 1, 2, 2, 2, 1],
            names: ["Ionian / Major", "Dorian", "Phrygian", "Lydian", "Mixolydian", "Aeolian / Minor", "Locrian"],
            chordTypes: ["", "m", "m", "", "", "m", "dim"]
        },
        {
            name: "Blues",
            equation: [2, 1, 1, 2, 1, 2, 3],
            names: ["Ionian / Major", "Dorian", "Phrygian", "Lydian", "Mixolydian", "Aeolian / Minor", "Locrian"],
            chordTypes: ["", "", "", "", "", "", ""]
        },
        {
            name: "Harmonic Minor",
            equation: [2, 1, 2, 2, 1, 3, 1],
            names: ["I: Dorian b5", "II: Locrian 13", "III: Ionian #5", "IV: Dorian #11", "V: Phrygian Dominant", "VI: Lydian #2", "VII: Super Locrian bb7"],
            chordTypes: ["m", "dim", "aug", "m", "", "", "dim"]
        },
        {
            name: "Harmonic Major",
            equation: [2, 2, 1, 2, 1, 3, 1],
            names: ["I: Ionian b6", "II: Dorian b5", "III: Phrygian b4", "IV: Lydian b3", "V: Mixolydian b2", "VI: Lydian Augmented #2", "VII: Locrian bb7"],
            chordTypes: ["", "dim", "m", "m", "", "aug", "dim"]
        },
];

export const getFretboard = (tunings: Notes[], numFrets: number) => {
    let fretboard = new Array(tunings.length).fill(Notes.C).map(() => new Array(numFrets).fill(Notes.C));

    for(let i = 0; i < tunings.length; i++) {
        const string: Notes = tunings[i];

        for(let j = 0; j < numFrets; j++) {
            fretboard[i][j] = Notes[Notes[(string + j) % 12]];
        }
    }

    return fretboard;
};

export const getChordsInScale = (selectedMode: Mode, offset: number, startNoteIndex: number, scale: string) => {
    let notesInKey: Notes[] = [];
    let totalSteps = 0;
    let len = selectedMode.equation.length;

    for(let i = 0; i < len; i++) {
        notesInKey[i] = Notes[Notes[(startNoteIndex + totalSteps) % 12]];
        totalSteps += selectedMode.equation[(i + offset) % len];
    }

    return removeUnneededNotes(notesInKey, scale, offset);
};

const removeUnneededNotes = (notesInKey: Notes[], scale: string, offset: number) => {
    if(scale === "Pentatonic") {
        // Don't use 4th or 7th notes for Major
        let indexToRemove1 = (3 - offset) % 7;
        let indexToRemove2 = (6 - offset) % 7;

        if(indexToRemove1 >= indexToRemove2) {
            notesInKey.splice(indexToRemove1, 1);
            notesInKey.splice(indexToRemove2, 1);
        } else {
            notesInKey.splice(indexToRemove2, 1);
            notesInKey.splice(indexToRemove1, 1);
        }
    } else if(scale === "Triads") {
        notesInKey.splice(6, 1); // 7
        notesInKey.splice(5, 1); // 6
        notesInKey.splice(3, 1); // 4
        notesInKey.splice(1, 1); // 2
    }

    return notesInKey;
};

export const getChordEquation = (mode: string, offset: number, startNoteIndex: number) => {
    const selectedMode: Mode = getMode(mode);
    const len = selectedMode.equation.length;
    let chordEq = "";
    let totalSteps = 0;

    for(let i = 0; i < len; i++) {
        chordEq += NoteNames[(startNoteIndex + totalSteps) % 12] + selectedMode.chordTypes[((i + offset) % len)];
        
        if(i !== len - 1) {
            chordEq += " -> ";
        }
        totalSteps += selectedMode.equation[(i + offset) % len];
    }

    return chordEq;
};

export const getChordType = (selectedMode: Mode, offset: number, keyNum: number) => {
    const len = selectedMode.equation.length;

    return selectedMode.chordTypes[((keyNum + offset) % len)];
};

export const getMode = (modeType: string) => {
    for(let i = 0; i < modes.length; i++) {
        if(modes[i].name === modeType) {
            return modes[i];
        }
    }

    return modes[0];
};
