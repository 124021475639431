import React from 'react';
import { useSession, Notes, NoteNames, getFretboard, getChordsInScale, getMode } from '../hooks';
import { useStrictMode, Stage, Layer, Text, Line, Circle, Group } from 'react-konva';
import { Sidebar } from './Sidebar';
import "./Strings.css";

export function Strings(props: any) {
    useStrictMode(true);
    const [state] = useSession();
    const {mode, scale, startNote, stringTuning} = state;

    // Constants
    const WIDTH = window.innerWidth * .85;
    const HEIGHT = window.innerHeight * .775;
    const NORMAL_WIDTH = 1632;
    const NORMAL_HEIGHT = 725;
    const MULTIPLIER_X = (WIDTH / NORMAL_WIDTH);
    const MULTIPLIER_Y = (HEIGHT / NORMAL_HEIGHT);
    const SMALLER_MULTIPLIER = (MULTIPLIER_X <= MULTIPLIER_Y) ? MULTIPLIER_X : MULTIPLIER_Y;

    const INITIAL_OFFSET_X = 60 * MULTIPLIER_X;
    const INITIAL_OFFSET_Y = 100 * MULTIPLIER_Y;
    const FRET_RADIUS_LARGE = 40 * SMALLER_MULTIPLIER;
    const FRET_RADIUS_SMALL = 25 * SMALLER_MULTIPLIER;
    const FRET_RADIUS_X_SMALL = 12 * SMALLER_MULTIPLIER;
    const PX_BETWEEN_FRETS_X = FRET_RADIUS_LARGE * 2 + (30 * MULTIPLIER_X);
    const PX_BETWEEN_FRETS_Y = FRET_RADIUS_LARGE * 2 + (20 * MULTIPLIER_Y);
    const NUM_FRETS = 13;
    const LINE_HEIGHT = PX_BETWEEN_FRETS_Y * stringTuning.length - (30 * MULTIPLIER_Y);
    const LINE_OFFSET = INITIAL_OFFSET_X + PX_BETWEEN_FRETS_X/2;
    const FONT_SIZE = 20 * SMALLER_MULTIPLIER;
    const CIRCLE_FRET = [3, 5, 7, 9];
    const TWO_CIRCLE_FRET = [0];
    const FRET_CIRCLE_OFFSET = FRET_RADIUS_LARGE + FRET_RADIUS_X_SMALL + (15 * SMALLER_MULTIPLIER);

    const fretboard = getFretboard(stringTuning, NUM_FRETS);
    const selectedMode = getMode(mode.modeType);
    const offset = selectedMode.names.indexOf(mode.modeName);
    const startNoteIndex = NoteNames.indexOf(startNote);
    const notesInKey = getChordsInScale(selectedMode, offset, startNoteIndex, scale);

    const isNoteInKey = (note: Notes) => {
        return notesInKey.indexOf(note) !== -1;
    };

    const showFretCircle = (fret: number) => {
        return CIRCLE_FRET.indexOf(fret % 12) !== -1;
    }

    const showTwoFretCircle = (fret: number) => {
        if(fret !== 0) {
            return TWO_CIRCLE_FRET.indexOf(fret % 12) !== -1;
        }

        return false;
    }

    return (
        <div className="row no-gutters">
            <div className="col">
                <Stage width={WIDTH} height={HEIGHT}>
                    <Layer>
                        {fretboard.map((string: Notes[], i: number) => {
                                return <Group key={i}>
                                    <Line key={" " + i} points={[INITIAL_OFFSET_X/2, i*PX_BETWEEN_FRETS_Y + INITIAL_OFFSET_Y, 
                                            NUM_FRETS*PX_BETWEEN_FRETS_X + INITIAL_OFFSET_X/2, i*PX_BETWEEN_FRETS_Y + INITIAL_OFFSET_Y]} 
                                            strokeWidth={4} stroke="black" />
                                    {string.map((note: Notes, j: number) => {
                                            return <Group key={j}>
                                                {i === 0 && <Text fontSize={FONT_SIZE} fontStyle="bold" key={"    " + j} text={"" + j} 
                                                        x={j*PX_BETWEEN_FRETS_X + INITIAL_OFFSET_X - FONT_SIZE/2} y={INITIAL_OFFSET_Y/2 - FONT_SIZE} />
                                                }
                                                <Circle key={"  " + j} radius={isNoteInKey(note) ? FRET_RADIUS_LARGE : FRET_RADIUS_SMALL} 
                                                        x={j*PX_BETWEEN_FRETS_X + INITIAL_OFFSET_X} y={i*PX_BETWEEN_FRETS_Y + INITIAL_OFFSET_Y} 
                                                        fill={isNoteInKey(note) ? "orange" : "lightgray"} stroke="black" strokeWidth={1} />
                                                <Text key={" " + j} text={NoteNames[(string[0] + j) % 12]} x={j*PX_BETWEEN_FRETS_X + INITIAL_OFFSET_X - FONT_SIZE/2} 
                                                        y={i*PX_BETWEEN_FRETS_Y + INITIAL_OFFSET_Y - FONT_SIZE/2} fontSize={FONT_SIZE} fontStyle="bold" />
                                                { i === 0 && j !== NUM_FRETS - 1 && <Line strokeWidth={j === 0 ? 8 : 4} stroke="black" key={"   " + j} 
                                                        points={[j*PX_BETWEEN_FRETS_X + LINE_OFFSET, INITIAL_OFFSET_Y/4, j*PX_BETWEEN_FRETS_X + LINE_OFFSET, 
                                                            INITIAL_OFFSET_Y + LINE_HEIGHT]} />
                                                }
                                                {(i === fretboard.length - 1) && showFretCircle(j) && <Circle key={"    " + j} radius={FRET_RADIUS_X_SMALL} 
                                                        x={j*PX_BETWEEN_FRETS_X + INITIAL_OFFSET_X} y={i*PX_BETWEEN_FRETS_Y + INITIAL_OFFSET_Y + FRET_CIRCLE_OFFSET} 
                                                        fill="black" />
                                                }
                                                {(i === fretboard.length - 1) && showTwoFretCircle(j) && <Group key={"      " + j}>
                                                        <Circle key={"    " + j} radius={FRET_RADIUS_X_SMALL} 
                                                                x={j*PX_BETWEEN_FRETS_X + INITIAL_OFFSET_X - FRET_RADIUS_X_SMALL*1.5} 
                                                                y={i*PX_BETWEEN_FRETS_Y + INITIAL_OFFSET_Y + FRET_CIRCLE_OFFSET} fill="black" />
                                                        <Circle key={"     " + j} radius={FRET_RADIUS_X_SMALL} 
                                                                x={j*PX_BETWEEN_FRETS_X + INITIAL_OFFSET_X + FRET_RADIUS_X_SMALL*1.5} 
                                                                y={i*PX_BETWEEN_FRETS_Y + INITIAL_OFFSET_Y + FRET_CIRCLE_OFFSET} fill="black" />
                                                    </Group>
                                                }
                                            </Group>
                                        })
                                    }
                                </Group>
                            })
                        }
                    </Layer>
                </Stage>
            </div>
            <Sidebar />
            <div className="col3"></div>
        </div>
    );
}
