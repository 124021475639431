import React from 'react';
import { useSession, Notes, NoteNames, getChordsInScale, getMode } from '../hooks';
import { useStrictMode, Stage, Layer, Text, Rect, Group } from 'react-konva';
import { Sidebar } from './Sidebar';
import "./Strings.css";

export function Keys(props: any) {
    useStrictMode(true);
    const [state] = useSession();
    const {mode, scale, startNote} = state;
    const keys = props.keys;
    const blackKeys: Notes[] = keys.BlackKeys;
    const whiteKeys: Notes[] = keys.WhiteKeys;

    const selectedMode = getMode(mode.modeType);
    const offset = selectedMode.names.indexOf(mode.modeName);
    const startNoteIndex = NoteNames.indexOf(startNote);
    const notesInKey = getChordsInScale(selectedMode, offset, startNoteIndex, scale);

    // Constants
    const WIDTH = window.innerWidth * .85;
    const HEIGHT = window.innerHeight * .775;
    const NORMAL_WIDTH = 1632;
    const NORMAL_HEIGHT = 725;
    const MULTIPLIER_X = (WIDTH / NORMAL_WIDTH);
    const MULTIPLIER_Y = (HEIGHT / NORMAL_HEIGHT);
    const SMALLER_MULTIPLIER = (MULTIPLIER_X <= MULTIPLIER_Y) ? MULTIPLIER_X : MULTIPLIER_Y;

    const ITERATIONS = 2;
    const INITIAL_OFFSET_X = 100 * MULTIPLIER_X;
    const INITIAL_OFFSET_Y = 50 * MULTIPLIER_Y;
    const KEY_HEIGHT = 250 * MULTIPLIER_Y;
    const KEY_WIDTH = 100 * MULTIPLIER_X;
    const BLACK_KEY_OFFSET = KEY_WIDTH/2;
    const NUM_KEYS = 7;
    const FONT_SIZE = 20 * SMALLER_MULTIPLIER;

    const isNoteInKey = (note: Notes) => {
        return notesInKey.indexOf(note) !== -1;
    };

    return (
        <div className="row no-gutters">
            <div className="col">
                <Stage width={WIDTH} height={HEIGHT}>
                    <Layer>
                        {Array.from(Array(ITERATIONS)).map((value: any, i: number) => {
                                return <Group key={i}>
                                    {blackKeys.map((note: Notes | null, j: number) => {
                                            return <Group key={j}>
                                                {note !== null && <Group key={" " + j}>
                                                        <Rect key={"  " + j} stroke="black" strokeWidth={1} fill={isNoteInKey(note) ? "orange" : "lightgray"} width={KEY_WIDTH}
                                                                height={KEY_HEIGHT} x={BLACK_KEY_OFFSET + KEY_WIDTH*j + INITIAL_OFFSET_X + i*NUM_KEYS*KEY_WIDTH} 
                                                                y={INITIAL_OFFSET_Y} />
                                                        <Text fontSize={FONT_SIZE} fontStyle="bold" key={"    " + j} text={NoteNames[note]}
                                                                x={BLACK_KEY_OFFSET + KEY_WIDTH*j + INITIAL_OFFSET_X + i*NUM_KEYS*KEY_WIDTH + KEY_WIDTH/2 - FONT_SIZE/2} 
                                                                y={INITIAL_OFFSET_Y/2 + KEY_HEIGHT/2 - FONT_SIZE/2} />
                                                    </Group>
                                                }
                                                
                                            </Group>
                                        })
                                    }
                                    {whiteKeys.map((note: Notes, j: number) => {
                                            return <Group key={j}>
                                                <Rect key={"  " + j} stroke="black" strokeWidth={1} fill={isNoteInKey(note) ? "orange" : "white"} width={KEY_WIDTH}
                                                        height={KEY_HEIGHT} x={KEY_WIDTH*j + INITIAL_OFFSET_X + i*NUM_KEYS*KEY_WIDTH} 
                                                        y={INITIAL_OFFSET_Y + KEY_HEIGHT} />
                                                <Text fontSize={FONT_SIZE} fontStyle="bold" key={"    " + j} text={NoteNames[note]}
                                                                x={KEY_WIDTH*j + INITIAL_OFFSET_X + i*NUM_KEYS*KEY_WIDTH + KEY_WIDTH/2 - FONT_SIZE/2} 
                                                                y={INITIAL_OFFSET_Y + KEY_HEIGHT + KEY_HEIGHT/2 - FONT_SIZE/2} />
                                            </Group>
                                        })
                                    }
                                </Group>
                            })
                        }
                    </Layer>
                </Stage>
            </div>
            <Sidebar />
            <div className="col3"></div>
        </div>
    );
}
