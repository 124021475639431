import React from 'react';
import { Instruments, modes, useSession, ScaleTypes, NoteNames, getChordEquation, getStringTuning } from '../hooks';
import Dropdown from '../lib/index';
import './Header.css';

export function Header() {
    const [state, dispatch] = useSession();
    const {instrument, mode, scale, startNote} = state;
    const {setInstrument, setMode, setScale, setStartNote, setStringTuning} = dispatch;

    const selectStartNote = (value: string) => {
        localStorage.setItem("StartNote", value);
        setStartNote(value);
    }

    const selectScale = (value: string) => {
        localStorage.setItem("Scale", value);
        setScale(value);
    };

    const selectInstrument = (value: string) => {
        setInstrument(value);
        localStorage.setItem("Instrument", value);

        const tuning = getStringTuning(value);
        setStringTuning(tuning);
    };

    const selectMode = (type: string, name: string) => {
        localStorage.setItem("ModeType", type);
        localStorage.setItem("ModeName", name);
        setMode({modeType: type, modeName: name});
    };

    const isActive = (note: string) => {
        return note === startNote;
    }

    return (
        <div>
            <nav className="navbar nav">
                <h1>Scale Visualizer</h1>
                <div className="dropdown">
                    <h2>Select Instrument: </h2>
                    <Dropdown title={instrument}>
                        {Instruments.map((value, index) => {
                                return <Dropdown.Item key={index} onClick={() => selectInstrument(value)}>
                                        {value}
                                    </Dropdown.Item>
                            })
                        }
                    </Dropdown>
                </div>
                <div className="dropdown">
                    <h2>Select Mode: </h2>
                    <Dropdown title={mode.modeType + " > " + mode.modeName}>
                        {modes.map((type, index) => {
                                return <Dropdown.Item key={index}>
                                    {type.name}
                                    <Dropdown.Submenu key={index}>
                                        {type.names.map((name, index2) => {
                                                return <Dropdown.Item key={index2}  onClick={() => selectMode(type.name, name)}>
                                                    {name}
                                                </Dropdown.Item>
                                            })
                                        }
                                    </Dropdown.Submenu>
                                </Dropdown.Item>
                            })
                        }
                    </Dropdown>
                </div>
                <div className="dropdown">
                    <h2>Select Scale Type: </h2>
                    <Dropdown title={scale}>
                        {ScaleTypes.map((value, index) => {
                                return <Dropdown.Item key={index} onClick={() => selectScale(value)}>
                                        {value}
                                    </Dropdown.Item>
                            })
                        }
                    </Dropdown>
                </div>
                <div className="container-fluid flex-column keys">
                    <div className="row no-gutters container-fluid">
                        {NoteNames.map((note: string, index: number) => {
                                return <div key={index} className={"button key col-sm " + (isActive(note) ? "active" : "")}>
                                    <p onClick={() => selectStartNote(note)}>{note}</p>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className="container-fluid flex-column">
                    <div className="row">
                        <div className="col-l major" onClick={() => selectMode("Major", "Ionian / Major")}>
                            <h4>{"Major: " + getChordEquation("Major", 0, NoteNames.indexOf(startNote))}</h4>
                        </div>
                        <div className="col-l minor" onClick={() => selectMode("Major", "Aeolian / Minor")}>
                            <h4>{"Minor: " + getChordEquation("Major", 5, NoteNames.indexOf(startNote))}</h4>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}
