"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _DropdownModule = _interopRequireDefault(require("./Dropdown.module.css"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactStyleClasses = _interopRequireDefault(require("react-style-classes"));

var _Item = _interopRequireDefault(require("./Item"));

var _Submenu = _interopRequireDefault(require("./Submenu"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var Dropdown = function Dropdown(props) {
  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      isOpen = _useState2[0],
      setOpen = _useState2[1];

  var dropdownRef = (0, _react.useRef)();
  (0, _react.useEffect)(function () {
    document.addEventListener('mousedown', handleClick);
    return function () {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
  var handleClick = (0, _react.useCallback)(function (e) {
    if (!dropdownRef.current.contains(e.target)) {
      setOpen(false);
    }
  }, []);

  var handleButtonOnClick = function handleButtonOnClick() {
    if (props.isDisabled) {
      return;
    }

    setOpen(!isOpen);
  };

  return _react.default.createElement("div", {
    className: (0, _reactStyleClasses.default)(_DropdownModule.default.dropdown, props.wrapperClassName),
    ref: dropdownRef
  }, _react.default.createElement("button", {
    type: "button",
    className: (0, _reactStyleClasses.default)(_DropdownModule.default.button, isOpen && _DropdownModule.default.active, props.isDisabled && _DropdownModule.default.disabled, props.buttonClassName),
    disabled: props.isDisabled,
    tabIndex: 0,
    onClick: handleButtonOnClick
  }, props.title), isOpen && _react.default.createElement("div", {
    className: (0, _reactStyleClasses.default)(_DropdownModule.default.menu, props.position === 'right' && _DropdownModule.default.menuRight, props.menuClassName),
    onClick: handleButtonOnClick
  }, _react.default.createElement("ul", null, props.children)));
};

Dropdown.propTypes = {
  title: _propTypes.default.any,
  children: _propTypes.default.node,
  isDisabled: _propTypes.default.bool,
  position: _propTypes.default.oneOf(['left', 'right']),
  wrapperClassName: _propTypes.default.string,
  buttonClassName: _propTypes.default.string,
  menuClassName: _propTypes.default.string
};
Dropdown.defaultProps = {
  children: null,
  isDisabled: false,
  title: null,
  position: 'left',
  wrapperClassName: null,
  buttonClassName: null,
  menuClassName: null
};
Dropdown.Item = _Item.default;
Dropdown.Submenu = _Submenu.default;
var _default = Dropdown;
exports.default = _default;